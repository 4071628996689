import * as ActionCompetition from '../../Competition/Store/CompetitionAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionWinner from '../Store/WinnerAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsCompetition from '../../Competition/Selector/CompetitionSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsWinner from '../Selector/WinnerSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import ModalWinnerComponent from '../Component/ModalWinnerComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalWinnerContainer(props) {
  const {
    componentAction,
    winnerAction,
    modalWinnerAction,
    competitionAction,
    winnerDetail,
  } = props;

  useEffect(() => {
    competitionAction.fetchCompetitionListRequested();
  }, [competitionAction]);
  const handleCancel = () => {
    componentAction.openModal('Winner');
    winnerAction.setWinnerModalData('register');
    winnerAction.resetForm();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (modalWinnerAction === 'register') {
      winnerAction.registerWinnerRequested();
    } else {
      winnerAction.updateWinnerRequested();
    }
  };

  const initialValues: any = {};
  if (modalWinnerAction === 'update' && Object.keys(winnerDetail).length > 0) {
    initialValues.picture = winnerDetail.picture;
    initialValues.winner = winnerDetail.winner;
    initialValues.competition = winnerDetail.competition.id;
  }

  return (
    <ModalWinnerComponent
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.makeIsLoading(),
  errorFormValue: getFormSyncErrors('winnerForm'),
  winnerDetail: SelectorsWinner.makeWinnerDetailSelector(),
  modalWinnerAction: SelectorsWinner.makeWinnerModalActionSelector(),
  winnerModalIsShow: SelectorsComponent.winnerModalIsShow(),
  token: SelectorsAuth.makeTokenSelector(),
  competitionList: SelectorsCompetition.makeCompetitionListSelector(),
});

const mapDispatchToProps = dispatch => ({
  winnerAction: bindActionCreators(ActionWinner, dispatch),
  competitionAction: bindActionCreators(ActionCompetition, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalWinnerContainer);
