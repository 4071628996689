const CompetitionAddValidation = (values, props) => {
  const errors: any = {};
  if (!values.closedate) {
    errors.closedate = 'Close date required!';
  }
  if (!values.name) {
    errors.name = 'Name required!';
  } else {
    if (values.name.length < 3) {
      errors.name = 'Min length name 5 character!';
    } else if (values.name.length > 255) {
      errors.name = 'Max length name 255 character!';
    }
  }
  for (let index = 0; index < 4; index++) {
    let lang;
    switch (index) {
      case 0:
        lang = 'EN';
        break;
      case 1:
        lang = 'DE';
        break;
      case 2:
        lang = 'FR';
        break;
      case 3:
        lang = 'IT';
        break;
    }
    if (!values[`prize${lang}`]) {
      errors[`prize${lang}`] = `Prize ${lang} required!`;
    } else {
      if (values[`prize${lang}`].length < 3) {
        errors[`prize${lang}`] = `Min length prize ${lang} 5 character!`;
      } else if (values[`prize${lang}`].length > 255) {
        errors[`prize${lang}`] = `Max length prize ${lang} 255 character!`;
      }
    }
    if (!values[`header${lang}`]) {
      errors[`header${lang}`] = `Header ${lang} required!`;
    } else {
      if (values[`header${lang}`].length < 3) {
        errors[`header${lang}`] = `Min length header ${lang} 5 character!`;
      } else if (values[`header${lang}`].length > 255) {
        errors[`header${lang}`] = `Max length header ${lang} 255 character!`;
      }
    }
    if (values[`titlequestion${lang}`]) {
      if (!values[`titlequestion${lang}`]) {
        errors[`titlequestion${lang}`] = `Question ${lang} required!`;
      } else {
        if (values[`titlequestion${lang}`].length < 3) {
          errors[
            `titlequestion${lang}`
          ] = `Min length question ${lang} 5 character!`;
        } else if (values[`titlequestion${lang}`].length > 255) {
          errors[
            `titlequestion${lang}`
          ] = `Max length question ${lang} 255 character!`;
        }
      }
      if (!values[`answer1${lang}`]) {
        errors[`answer1${lang}`] = `Answer ${lang} 1 required!`;
      } else {
        if (values[`answer1${lang}`].length < 3) {
          errors[
            `answer1${lang}`
          ] = `Min length answer ${lang} 1 is 3 character!`;
        } else if (values[`answer1${lang}`].length > 255) {
          errors[
            `answer1${lang}`
          ] = `Max length answer ${lang} is 255 character!`;
        }
      }
      if (!values[`answer2${lang}`]) {
        errors[`answer2${lang}`] = `Answer ${lang} 2 required!`;
      } else {
        if (values[`answer2${lang}`].length < 3) {
          errors[
            `answer2${lang}`
          ] = `Min length answer ${lang} 2 is 3 character!`;
        } else if (values[`answer2${lang}`].length > 255) {
          errors[
            `answer2${lang}`
          ] = `Max length answer ${lang} 2 is 255 character!`;
        }
      }
      if (!values[`answer3${lang}`]) {
        errors[`answer3${lang}`] = `Answer ${lang} 3 required!`;
      } else {
        if (values[`answer3${lang}`].length < 3) {
          errors[
            `answer3${lang}`
          ] = `Min length answer ${lang} 3 is 3 character!`;
        } else if (values[`answer3${lang}`].length > 255) {
          errors[
            `answer3${lang}`
          ] = `Max length answer ${lang} 3 is 255 character!`;
        }
      }
      if (!values.answer) {
        errors.answer = `Correct answer required!`;
      }
    } else if (
      !values[`titlequestion${lang}`] &&
      (values[`question${lang}`] ||
        values[`answer1${lang}`] ||
        values[`answer2${lang}`] ||
        values[`answer3${lang}`] ||
        values.answer)
    ) {
      errors.titlequestion = `If question / answer 1 / answer 2 / answer 3 / correct answer filled, title question required!`;
    }
  }
  return errors;
};
export default CompetitionAddValidation;
