import { Breadcrumb } from 'antd';
import CButton from '../../../Assets/Components/CButton';
import CTable from '../../../Assets/Components/CTable';
import ModalWinnerContainer from '../Container/ModalWinnerContainer';
import ModalWinnerDescriptionContainer from '../Container/ModalWinnerDescriptionContainer';
import React from 'react';

export default function FormWinnerComponent(props) {
  const {
    columns,
    loading,
    list,
    handleAddWinner,
    handleRefresh,
    handleUpdateWinnerDesription,
  } = props;
  const buttonData = [
    {
      type: 'primary',
      icon: 'edit',
      onClick: handleUpdateWinnerDesription,
      content: 'Update Page Description',
      id: 'btnChangePageWinnerDescription',
    },
    {
      type: 'primary',
      icon: 'plus',
      onClick: handleAddWinner,
      content: 'Add Winner',
      id: 'btnAddWinner',
    },
    {
      type: 'default',
      icon: 'reload',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefresh',
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Winner</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={loading}
        />
        <CTable
          data={list}
          columns={columns}
          minRows={0}
          defaultPageSize={10}
          isLoading={loading}
          pagination={true}
          useFilterGlobal={true}
        />
        <ModalWinnerContainer />
        <ModalWinnerDescriptionContainer />
      </div>
    </>
  );
}
