import { createSelector } from 'reselect';

const selectorWinnerList = state => state.WinnerState;
const selectorFormWinner = state => state.form.winnerForm.values;
const selectorFormWinnerDescription = state =>
  state.form.winnerDescriptionForm.values;

// SELECTOR OTHER
export const makeWinnerListSelector = () =>
  createSelector(selectorWinnerList, state => state.list);
export const makeWinnerSelectedSelector = () =>
  createSelector(selectorWinnerList, state => state.selectedWinner);
export const makeWinnerDetailSelector = () =>
  createSelector(selectorWinnerList, state => state.selectedWinnerData);
export const makeWinnerPageDescriptionSelector = () =>
  createSelector(selectorWinnerList, state => state.winnerPageDescription);
export const makeWinnerModalActionSelector = () =>
  createSelector(
    selectorWinnerList,
    state => state.additionalExpenseModalAction
  );

// SELECTOR FORM
export const makeFormCompetitionSelector = () =>
  createSelector(selectorFormWinner, state => state.competition);
export const makeFormWinnerSelector = () =>
  createSelector(selectorFormWinner, state => state.winner);
export const makeFormText1ENSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text1EN);
export const makeFormText2ENSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text2EN);
export const makeFormText1DESelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text1DE);
export const makeFormText2DESelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text2DE);
export const makeFormText1FRSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text1FR);
export const makeFormText2FRSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text2FR);
export const makeFormText1ITSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text1IT);
export const makeFormText2ITSelector = () =>
  createSelector(selectorFormWinnerDescription, state => state.text2IT);
