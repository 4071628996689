import { Button, Col, Divider, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InputSelect, InputText } from '../../../Assets/Components/CInput';
import {
  uppyCompetitionBackground,
  uppyCompetitionBanner,
  uppyCompetitionLogo,
} from '../../../App/Uppy/UppyStore';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CModal from '../../../Assets/Components/CModal';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import { InputFile } from '../../../Assets/Components/CInputFile';
import React from 'react';
import { showImagePreview } from '../../../Assets/Components/CImageLightbox';
import validate from '../Validation/CompetitionValidation';

function FormModalCompetitionComponent(props) {
  const {
    competitionModalIsShow,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    initialValues,
    componentAction,
    token,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="competitionForm" />
      </h3>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        className="btnSubmitModalRoundedGrey"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        Submit
      </Button>
    );
  };

  const loopingFormLang = lang => {
    return (
      <Row gutter={20}>
        <Divider />
        <h3>{lang} Language</h3>
        <Col xs={12} md={12}>
          <Field
            name={`headline${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderHeadline' })}
            idComponent={`inputHeadline${lang}`}
            label={intl.formatMessage({
              id: 'headline',
            })}
          />
          <Field
            name={`subheadline${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderSubHeadline' })}
            idComponent={`inputSubHeadline${lang}`}
            label={intl.formatMessage({
              id: 'subheadline',
            })}
          />
          <Field
            name={`prize${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderPrize' })}
            idComponent={`inputPrize${lang}`}
            label={intl.formatMessage({
              id: 'prize',
            })}
          />
          <Field
            name={`header${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderHeader' })}
            idComponent={`inputHeader${lang}`}
            label={intl.formatMessage({
              id: 'header',
            })}
          />
          <Field
            name={`description${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderDescription' })}
            idComponent={`inputDescription${lang}`}
            label={intl.formatMessage({
              id: 'description',
            })}
            textArea={true}
          />
          <Field
            name={`titlequestion${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({
              id: 'placeholderTitleQuestion',
            })}
            idComponent={`inputTitleQuestion${lang}`}
            label={intl.formatMessage({
              id: 'titlequestion',
            })}
          />
        </Col>
        <Col xs={12} md={12}>
          <Field
            name={`question${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderQuestion' })}
            idComponent={`inputQuestion${lang}`}
            label={intl.formatMessage({
              id: 'question',
            })}
          />
          <Field
            name={`answer1${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderAnswer1' })}
            idComponent={`inputAnswer1${lang}`}
            label={intl.formatMessage({
              id: 'answer1',
            })}
          />
          <Field
            name={`answer2${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderAnswer2' })}
            idComponent={`inputAnswer2${lang}`}
            label={intl.formatMessage({
              id: 'answer2',
            })}
          />
          <Field
            name={`answer3${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderAnswer3' })}
            idComponent={`inputAnswer3${lang}`}
            label={intl.formatMessage({
              id: 'answer3',
            })}
          />
        </Col>
      </Row>
    );
  };

  const contentComponent = () => {
    return (
      <React.Fragment>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={12} md={12}>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderName' })}
              idComponent="inputName"
              label={intl.formatMessage({
                id: 'name',
              })}
            />
            <Field
              name="closedate"
              component={InputDatePicker}
              placeholder={intl.formatMessage({ id: 'placeholderCloseDate' })}
              idComponent="inputCloseDate"
              typeDate="datePicker"
              label={intl.formatMessage({
                id: 'closeDate',
              })}
            />
            <Field
              name="competitionLogo"
              component={InputFile}
              uppyConfig={uppyCompetitionLogo}
              idComponent="inputCompetitionLogo"
              label="inputCompetitionLogo"
              formName="competitionForm"
              fieldName="competitionLogo"
              base64ToMeta={true}
            />
            {initialValues && initialValues.logo
              ? showImagePreview(
                  initialValues.logo,
                  'CompetitionLogo',
                  componentAction,
                  token,
                  'logo'
                )
              : null}
            <Field
              name="competitionBanner"
              component={InputFile}
              uppyConfig={uppyCompetitionBanner}
              idComponent="inputCompetitionBanner"
              label="inputCompetitionBanner"
              formName="competitionForm"
              fieldName="competitionBanner"
              base64ToMeta={true}
            />
            {initialValues && initialValues.banner
              ? showImagePreview(
                  initialValues.banner,
                  'CompetitionBanner',
                  componentAction,
                  token,
                  'banner'
                )
              : null}
          </Col>
          <Col xs={12} md={12}>
            <Field
              name="answer"
              component={InputSelect}
              dafaultItemName="Select correct answer"
              data={[
                { id: 1, name: 'Answer 1' },
                { id: 2, name: 'Answer 2' },
                { id: 3, name: 'Answer 3' },
              ]}
              placeholder={intl.formatMessage({ id: 'placeholderAnswer' })}
              idComponent="inputAnswer"
              label={intl.formatMessage({
                id: 'answer',
              })}
            />
            <Field
              name="competitionBackground"
              component={InputFile}
              uppyConfig={uppyCompetitionBackground}
              idComponent="inputCompetitionBackground"
              label="inputCompetitionBackground"
              formName="competitionForm"
              fieldName="competitionBackground"
              base64ToMeta={true}
            />
            {initialValues && initialValues.background
              ? showImagePreview(
                  initialValues.background,
                  'CompetitionBackground',
                  componentAction,
                  token,
                  'background'
                )
              : null}
          </Col>
        </Row>
        {loopingFormLang('EN')}
        {loopingFormLang('DE')}
        {loopingFormLang('FR')}
        {loopingFormLang('IT')}
      </React.Fragment>
    );
  };
  return (
    <CModal
      modalIsShow={competitionModalIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalCompetitionComponent = reduxForm({
  form: 'competitionForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(injectIntl(FormModalCompetitionComponent));
export default ModalCompetitionComponent;
