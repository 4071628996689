import { Action } from 'redux';
export interface ISetBaseAPI extends Action {
  baseAPI: string;
}
export interface ISetAppName extends Action {
  value: string;
}
export function handleError(actionData, errorData): Action {
  return {
    type: 'HANDLE_ERROR_SAGA',
    actionData,
    errorData,
  };
}

export function setAppName(value: string): ISetAppName {
  return {
    type: 'SET_APP_NAME',
    value,
  };
}
