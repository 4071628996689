import { createSelector } from 'reselect';

const selectorCompetitionList = state => state.CompetitionState;
const selectorFormCompetition = state => state.form.competitionForm.values;

// SELECTOR OTHER
export const makeCompetitionListSelector = () =>
  createSelector(selectorCompetitionList, state => state.list);
export const makeCompetitionSelectedSelector = () =>
  createSelector(selectorCompetitionList, state => state.selectedCompetition);
export const makeCompetitionDetailSelector = () =>
  createSelector(
    selectorCompetitionList,
    state => state.selectedCompetitionData
  );
export const makeCompetitionModalActionSelector = () =>
  createSelector(
    selectorCompetitionList,
    state => state.additionalExpenseModalAction
  );

// SELECTOR FORM
export const makeFormNameSelector = () =>
  createSelector(selectorFormCompetition, state => state.name);
export const makeFormAnswerSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer);
export const makeFormCloseDateSelector = () =>
  createSelector(selectorFormCompetition, state => state.closedate);

// SELECTOR FORM EN
export const makeFormHeadlineENSelector = () =>
  createSelector(selectorFormCompetition, state => state.headlineEN);
export const makeFormSubHeadlineENSelector = () =>
  createSelector(selectorFormCompetition, state => state.subheadlineEN);
export const makeFormPrizeENSelector = () =>
  createSelector(selectorFormCompetition, state => state.prizeEN);
export const makeFormHeaderENSelector = () =>
  createSelector(selectorFormCompetition, state => state.headerEN);
export const makeFormDescriptionENSelector = () =>
  createSelector(selectorFormCompetition, state => state.descriptionEN);
export const makeFormTitleQuestionENSelector = () =>
  createSelector(selectorFormCompetition, state => state.titlequestionEN);
export const makeFormQuestionENSelector = () =>
  createSelector(selectorFormCompetition, state => state.questionEN);
export const makeFormAnswer1ENSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer1EN);
export const makeFormAnswer2ENSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer2EN);
export const makeFormAnswer3ENSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer3EN);

// SELECTOR FORM DE
export const makeFormHeadlineDESelector = () =>
  createSelector(selectorFormCompetition, state => state.headlineDE);
export const makeFormSubHeadlineDESelector = () =>
  createSelector(selectorFormCompetition, state => state.subheadlineDE);
export const makeFormPrizeDESelector = () =>
  createSelector(selectorFormCompetition, state => state.prizeDE);
export const makeFormHeaderDESelector = () =>
  createSelector(selectorFormCompetition, state => state.headerDE);
export const makeFormDescriptionDESelector = () =>
  createSelector(selectorFormCompetition, state => state.descriptionDE);
export const makeFormTitleQuestionDESelector = () =>
  createSelector(selectorFormCompetition, state => state.titlequestionDE);
export const makeFormQuestionDESelector = () =>
  createSelector(selectorFormCompetition, state => state.questionDE);
export const makeFormAnswer1DESelector = () =>
  createSelector(selectorFormCompetition, state => state.answer1DE);
export const makeFormAnswer2DESelector = () =>
  createSelector(selectorFormCompetition, state => state.answer2DE);
export const makeFormAnswer3DESelector = () =>
  createSelector(selectorFormCompetition, state => state.answer3DE);

// SELECTOR FORM FR
export const makeFormHeadlineFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.headlineFR);
export const makeFormSubHeadlineFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.subheadlineFR);
export const makeFormPrizeFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.prizeFR);
export const makeFormHeaderFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.headerFR);
export const makeFormDescriptionFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.descriptionFR);
export const makeFormTitleQuestionFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.titlequestionFR);
export const makeFormQuestionFRSelector = () =>
  createSelector(selectorFormCompetition, state => state.questionFR);
export const makeFormAnswer1FRSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer1FR);
export const makeFormAnswer2FRSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer2FR);
export const makeFormAnswer3FRSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer3FR);

// SELECTOR FORM IT
export const makeFormHeadlineITSelector = () =>
  createSelector(selectorFormCompetition, state => state.headlineIT);
export const makeFormSubHeadlineITSelector = () =>
  createSelector(selectorFormCompetition, state => state.subheadlineIT);
export const makeFormPrizeITSelector = () =>
  createSelector(selectorFormCompetition, state => state.prizeIT);
export const makeFormHeaderITSelector = () =>
  createSelector(selectorFormCompetition, state => state.headerIT);
export const makeFormDescriptionITSelector = () =>
  createSelector(selectorFormCompetition, state => state.descriptionIT);
export const makeFormTitleQuestionITSelector = () =>
  createSelector(selectorFormCompetition, state => state.titlequestionIT);
export const makeFormQuestionITSelector = () =>
  createSelector(selectorFormCompetition, state => state.questionIT);
export const makeFormAnswer1ITSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer1IT);
export const makeFormAnswer2ITSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer2IT);
export const makeFormAnswer3ITSelector = () =>
  createSelector(selectorFormCompetition, state => state.answer3IT);
