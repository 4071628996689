import { Breadcrumb } from 'antd';
import CButton from '../../../Assets/Components/CButton';
import CTable from '../../../Assets/Components/CTable';
import ModalCompetitionContainer from '../Container/ModalCompetitionContainer';
import React from 'react';

export default function CompetitionComponent(props) {
  const { columns, loading, list, handleAddCompetition, handleRefresh } = props;
  const buttonData = [
    {
      type: 'primary',
      icon: 'plus',
      onClick: handleAddCompetition,
      content: 'Add Competition',
      id: 'btnAddCompetition',
    },
    {
      type: 'default',
      icon: 'reload',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefresh',
    },
  ];
  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Competition</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={loading}
        />
        <CTable
          data={list}
          columns={columns}
          minRows={0}
          defaultPageSize={10}
          isLoading={loading}
          pagination={true}
          useFilterGlobal={true}
        />
        <ModalCompetitionContainer />
      </div>
    </>
  );
}
