import ReduxStoreUppy from '@uppy/store-redux';
import Uppy from '@uppy/core';
import { localeUppy } from '../../Modules/Internationalization/translations/en';
import { store } from '../ConfigureStore';

const commonRestrictions = {
  minNumberOfFiles: 1,
  maxNumberOfFiles: 1,
  allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
  maxFileSize: 2621440,
};
export const uppyCompetitionLogo = Uppy({
  store: ReduxStoreUppy({
    id: 'competitionLogo',
    store,
  }),
  restrictions: {
    minNumberOfFiles: 1,
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/png'],
    maxFileSize: 2621440,
  },
  locale: localeUppy,
});

export const uppyCompetitionBanner = Uppy({
  store: ReduxStoreUppy({
    id: 'competitionBanner',
    store,
  }),
  restrictions: commonRestrictions,
  locale: localeUppy,
});

export const uppyCompetitionBackground = Uppy({
  store: ReduxStoreUppy({
    id: 'competitionBackground',
    store,
  }),
  restrictions: commonRestrictions,
  locale: localeUppy,
});

export const uppyWiner = Uppy({
  store: ReduxStoreUppy({
    id: 'winnerPicture',
    store,
  }),
  restrictions: commonRestrictions,
  locale: localeUppy,
});
