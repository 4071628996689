import * as ActionAuth from '../../Auth/Store/AuthAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';

import { bindActionCreators, compose } from 'redux';

import React from 'react';
import TemplateComponent from '../Component/TemplateComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

function TemplateContainer(props) {
  const { componentAction, authAction } = props;
  const handleChangePassword = () => {
    componentAction.openModal('ChangePassword');
  };
  const handleLogOut = () => {
    const toastrConfirmOptions = {
      onOk: () => {
        authAction.logout();
      },
    };
    toastr.confirm(`Are you sure to logout?`, toastrConfirmOptions);
  };

  return (
    <TemplateComponent
      handleChangePassword={handleChangePassword}
      handleLogOut={handleLogOut}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loggedUser: SelectorAuth.makeAuthUserSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  authAction: bindActionCreators(ActionAuth, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TemplateContainer);
