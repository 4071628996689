import { Col, Descriptions, Row } from 'antd';

import CModal from '../../../Assets/Components/CModal';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import moment from 'moment';

export default function ParticipantComponent(props) {
  const {
    participantModalIsShow,
    handleCancel,
    handleSubmit,
    loading,
    initialValues,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="participantForm" />
      </h3>
    );
  };
  const footerComponent = () => {
    return null;
  };
  const contentComponent = () => {
    if (Object.keys(initialValues).length > 0) {
      const {
        firstname,
        middlename,
        phone,
        address,
        dob,
        postcode,
        competition,
      } = initialValues;
      return (
        <React.Fragment>
          <Row gutter={20}>
            <Col>
              <Descriptions
                title="Participant Info"
                layout="horizontal"
                bordered
                style={{ marginBottom: '30px' }}
              >
                <Descriptions.Item label="First Name" span={2}>
                  {firstname}
                </Descriptions.Item>
                <Descriptions.Item label="Last Name" span={2}>
                  {middlename}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth" span={3}>
                  {moment(dob).format('DD.MM.YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="Phone" span={3}>
                  {phone}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={3}>
                  {address}
                </Descriptions.Item>
                <Descriptions.Item label="Post Code" span={3}>
                  {postcode}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                title="Competition Info"
                layout="horizontal"
                bordered
              >
                <Descriptions.Item label="Name" span={3}>
                  {competition.name}
                </Descriptions.Item>
                <Descriptions.Item label="URL" span={3}>
                  {competition.url}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                  {competition.status}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
    return null;
  };
  return (
    <CModal
      modalIsShow={participantModalIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}
