import { Layout, Menu } from 'antd';

import { Link } from 'react-router-dom';
import ModalChangePasswordContainer from '../../Auth/Container/ModalChangePasswordContainer';
import React from 'react';

const { Header, Content, Footer } = Layout;

export default function TemplateComponent(props) {
  const { handleChangePassword, handleLogOut } = props;
  return (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="competition">
            <Link to="/competition">Competition</Link>
          </Menu.Item>
          <Menu.Item key="participant">
            <Link to="/participant">Participant</Link>
          </Menu.Item>
          {/* <Menu.Item key="winner">
            <Link to="/winner">Winner</Link>
          </Menu.Item> */}
          <Menu.Item key="changePassword" onClick={handleChangePassword}>
            Change Password
          </Menu.Item>
          <Menu.Item key="logOut" onClick={handleLogOut}>
            Log out
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>{props.children}</Content>
      <Footer style={{ textAlign: 'center' }}>
        Content Management System ©2020
      </Footer>
      <ModalChangePasswordContainer />
    </Layout>
  );
}
