import { Action } from 'redux';
export interface IFetchWinnerListSuccessAction extends Action {
  data: object;
}
export interface IFetchWinnerDetailSuccessAction extends Action {
  data: object;
}
export interface ISetWinnerPageDescriptionAction extends Action {
  data: object;
}
export interface ISetSelectedWinnerAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  data: object;
}
export interface ISetStatusWinnerAction extends Action {
  id: string;
  status: boolean;
}
export function registerWinnerRequested(): Action {
  return {
    type: 'REGISTER_WINNER_REQUESTED',
  };
}
export function updateWinnerRequested(): Action {
  return {
    type: 'UPDATE_WINNER_REQUESTED',
  };
}
export function updateWinnerDescriptionRequested(): Action {
  return {
    type: 'UPDATE_WINNER_DESCRIPTION_REQUESTED',
  };
}
export function fetchWinnerListRequested(): Action {
  return {
    type: 'FETCH_WINNER_LIST_REQUESTED',
  };
}
export function fetchWinnerDetailRequested(): Action {
  return {
    type: 'FETCH_WINNER_DETAIL_REQUESTED',
  };
}
export function setSelectedWinner(id: string): ISetSelectedWinnerAction {
  return {
    type: 'SET_SELECTED_WINNER',
    id,
  };
}
export function fetchWinnerListFinished(
  data: object
): IFetchWinnerListSuccessAction {
  return {
    type: 'FETCH_WINNER_LIST_FINISHED',
    data,
  };
}
export function setWinnerPageDescription(
  data: object
): ISetWinnerPageDescriptionAction {
  return {
    type: 'SET_WINNER_PAGE_DESCRIPTION',
    data,
  };
}
export function fetchWinnerDetailFinished(
  data: object
): IFetchWinnerListSuccessAction {
  return {
    type: 'FETCH_WINNER_DETAIL_FINISHED',
    data,
  };
}
export function setWinnerModalData(action, data): ISetModalDataAction {
  return {
    type: 'SET_WINNER_MODAL_DATA',
    action,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_WINNER',
  };
}
export function resetFormDescription() {
  return {
    type: 'RESET_FORM_WINNER_DESCRIPTION',
  };
}
export function deleteWinnerRequested() {
  return {
    type: 'DELETE_WINNER_REQUESTED',
  };
}
export function setStatusWinnerRequested(
  id: string,
  status: boolean
): ISetStatusWinnerAction {
  return {
    type: 'SET_STATUS_WINNER_REQUESTED',
    id,
    status,
  };
}
