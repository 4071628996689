import { Button, Col, Divider, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CModal from '../../../Assets/Components/CModal';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';

// import validate from '../Validation/WinnerPageValidation';

function FormModalWinnerComponent(props) {
  const {
    winnerDescriptionModalIsShow,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="winnerDescriptionForm" />
      </h3>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        className="btnSubmitModalRoundedGrey"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        Update
      </Button>
    );
  };

  const renderField = lang => {
    return (
      <Row gutter={20}>
        {lang !== 'EN' ? <Divider /> : null}
        <h3>{lang} Language</h3>
        <Col xs={24} md={24}>
          <Field
            name={`text1${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderText1' })}
            idComponent={`inputText1${lang}`}
            label={intl.formatMessage({
              id: 'inputText1',
            })}
          />
          <Field
            name={`text2${lang}`}
            component={InputText}
            placeholder={intl.formatMessage({ id: 'placeholderText2' })}
            idComponent={`inputText2${lang}`}
            label={intl.formatMessage({
              id: 'inputText2',
            })}
          />
        </Col>
      </Row>
    );
  };

  const contentComponent = () => {
    return (
      <React.Fragment>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        {renderField('EN')}
        {renderField('DE')}
        {renderField('FR')}
        {renderField('IT')}
      </React.Fragment>
    );
  };
  return (
    <CModal
      modalIsShow={winnerDescriptionModalIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalWinnerComponent = reduxForm({
  form: 'winnerDescriptionForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  // validate,
})(injectIntl(FormModalWinnerComponent));
export default ModalWinnerComponent;
