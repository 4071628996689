import { Button, Col, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CModal from '../../../Assets/Components/CModal';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/ChangePasswordValidation';

function FormModalUserChangePasswordComponent(props) {
  const {
    changePassword,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    modalUserAction,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="userChangePasswordForm" />
      </h3>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        className="btnSubmitModalRoundedGrey"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        Submit
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <React.Fragment>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="oldpassword"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderOldPassword' })}
              idComponent="inputPassword"
              label={intl.formatMessage({
                id: 'password',
              })}
              disabled={modalUserAction === 'update'}
            />
            <Field
              name="newpassword"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderNewPassword' })}
              idComponent="inputPassword"
              label={intl.formatMessage({
                id: 'newPassword',
              })}
              disabled={modalUserAction === 'update'}
            />
            <Field
              name="confirmpassword"
              component={InputText}
              placeholder={intl.formatMessage({
                id: 'placeholderConfirmPassword',
              })}
              idComponent="inputConfirmPassword"
              label={intl.formatMessage({
                id: 'confirmPassword',
              })}
              disabled={modalUserAction === 'update'}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  return (
    <CModal
      modalIsShow={changePassword}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={loading}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '800px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '1000',
        },
      }}
    />
  );
}

const ModalChangePasswordComponent = reduxForm({
  form: 'changePasswordForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(injectIntl(FormModalUserChangePasswordComponent));
export default ModalChangePasswordComponent;
