import { DatePicker, Form } from 'antd';

import React from 'react';
import moment from 'moment';

const formatDate = 'YYYY-MM-DD';

interface IPropsDatePicker {
  input?;
  meta?;
  placeholder?;
  idComponent?;
  icon?;
  label?;
  data?;
  disabled?;
  typeDate?;
  disabledDate?;
  update?;
}

const handleDefaultValue = (inputValue, typeDate) => {
  const convertToMoment: any = val => {
    return moment(val);
  };
  if (inputValue) {
    if (typeDate === 'dateRangePicker') {
      const startDate = moment.isMoment(inputValue[0])
        ? inputValue[0]
        : convertToMoment(inputValue[0]);
      const endDate = moment.isMoment(inputValue[1])
        ? inputValue[1]
        : convertToMoment(inputValue[1]);
      return [startDate, endDate];
    } else {
      if (moment.isMoment(inputValue)) {
        return inputValue;
      } else {
        return convertToMoment(inputValue);
      }
    }
  }
  return undefined;
};

export const InputDatePicker = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  typeDate,
  disabledDate,
  update,
}: IPropsDatePicker) => {
  const handleOnChange = e => input.onChange(e);
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
    </span>
  );
  const inputValue = input.value;

  const renderPicker = () => {
    if ((update && typeof inputValue === 'object') || !update) {
      if (typeDate === 'datePicker') {
        return (
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            format={formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      } else if (typeDate === 'dateRangePicker') {
        return (
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            format={formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      } else if (typeDate === 'monthPicker') {
        return (
          <DatePicker.MonthPicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      }
      return null;
    }
    return null;
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      {renderPicker()}
    </Form.Item>
  );
};
