import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionWinner from '../Store/WinnerAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsWinner from '../Selector/WinnerSelector';

import { Layout, Row } from 'antd';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import { FormattedMessage } from 'react-intl';
import WinnerComponent from '../Component/WinnerComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function WinnerContainer(props) {
  const { winnerAction, componentAction } = props;

  useEffect(() => {
    winnerAction.fetchWinnerListRequested();
  }, [winnerAction]);

  const handleAddWinner = () => {
    winnerAction.setWinnerModalData('register');
    componentAction.openModal('Winner');
  };

  const handleUpdateWinnerDesription = () => {
    componentAction.openModal('WinnerDescription');
  };

  const handleRefresh = () => {
    winnerAction.fetchWinnerListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      winnerAction.setSelectedWinner(row.row.original.id);
      winnerAction.setWinnerModalData('update', row.row.original);
      componentAction.openModal('Winner');
    };
    const deleteClick = () => {
      const toastrConfirmOptions = {
        onOk: () => {
          winnerAction.setSelectedWinner(row.row.original.id);
          winnerAction.deleteWinnerRequested();
        },
      };
      toastr.confirm(
        `Are you sure to delete "${row.row.original.winner}"?`,
        toastrConfirmOptions
      );
    };
    const buttonData = [
      {
        type: 'primary',
        icon: 'edit',
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailWinner-${row.row.index + 1}`,
        tooltipTitle: 'Edit',
      },
      {
        type: 'danger',
        icon: 'delete',
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteWinner-${row.row.index + 1}`,
        tooltipTitle: 'Delete',
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton
          buttonFloat="none"
          buttonData={buttonData}
          containerStyle={{ marginBottom: 0 }}
        />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: 'Winner',
      accessor: 'winner',
    },
    {
      Header: 'Competition',
      accessor: 'competition.name',
    },
    {
      Header: 'Prize',
      accessor: 'competition.prize',
    },
    {
      headerClassName: 'headerDatatableClassCenter',
      Header: () => <FormattedMessage id="action" />,
      accessor: 'action',
      Cell: renderAction,
      width: 100,
      disableSort: true,
    },
  ];
  return (
    <React.Fragment>
      <Content className="containerContent">
        <Row>
          <WinnerComponent
            columns={columns}
            handleAddWinner={handleAddWinner}
            handleRefresh={handleRefresh}
            handleUpdateWinnerDesription={handleUpdateWinnerDesription}
            {...props}
          />
        </Row>
      </Content>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsWinner.makeWinnerListSelector(),
  loading: SelectorsComponent.makeIsLoading(),
});

const mapDispatchToProps = dispatch => ({
  winnerAction: bindActionCreators(ActionWinner, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(WinnerContainer);
