import * as ActionApp from '../../App/Store/AppAction';
import * as ActionCompetition from '../Store/CompetitionAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorCompetition from '../Selector/CompetitionSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorUppy from '../../../App/Uppy/UppySelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import moment from 'moment';
import { toast } from 'react-toastify';

let toastMessage;

export function* getCompetitionListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/competition`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCompetition.fetchCompetitionListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getCompetitionDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const url = yield select(
      SelectorCompetition.makeCompetitionSelectedSelector()
    );
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/competition/${url}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionCompetition.fetchCompetitionDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewCompetitionProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const name = yield select(SelectorCompetition.makeFormNameSelector());
    const closedate = yield select(
      SelectorCompetition.makeFormCloseDateSelector()
    );
    const headlineEN = yield select(
      SelectorCompetition.makeFormHeadlineENSelector()
    );
    const subheadlineEN = yield select(
      SelectorCompetition.makeFormSubHeadlineENSelector()
    );
    const prizeEN = yield select(SelectorCompetition.makeFormPrizeENSelector());
    const headerEN = yield select(
      SelectorCompetition.makeFormHeaderENSelector()
    );
    const descriptionEN = yield select(
      SelectorCompetition.makeFormDescriptionENSelector()
    );
    const titlequestionEN = yield select(
      SelectorCompetition.makeFormTitleQuestionENSelector()
    );
    const questionEN = yield select(
      SelectorCompetition.makeFormQuestionENSelector()
    );
    const answer1EN = yield select(
      SelectorCompetition.makeFormAnswer1ENSelector()
    );
    const answer2EN = yield select(
      SelectorCompetition.makeFormAnswer2ENSelector()
    );
    const answer3EN = yield select(
      SelectorCompetition.makeFormAnswer3ENSelector()
    );
    const headlineDE = yield select(
      SelectorCompetition.makeFormHeadlineDESelector()
    );
    const subheadlineDE = yield select(
      SelectorCompetition.makeFormSubHeadlineDESelector()
    );
    const prizeDE = yield select(SelectorCompetition.makeFormPrizeDESelector());
    const headerDE = yield select(
      SelectorCompetition.makeFormHeaderDESelector()
    );
    const descriptionDE = yield select(
      SelectorCompetition.makeFormDescriptionDESelector()
    );
    const titlequestionDE = yield select(
      SelectorCompetition.makeFormTitleQuestionDESelector()
    );
    const questionDE = yield select(
      SelectorCompetition.makeFormQuestionDESelector()
    );
    const answer1DE = yield select(
      SelectorCompetition.makeFormAnswer1DESelector()
    );
    const answer2DE = yield select(
      SelectorCompetition.makeFormAnswer2DESelector()
    );
    const answer3DE = yield select(
      SelectorCompetition.makeFormAnswer3DESelector()
    );
    const headlineFR = yield select(
      SelectorCompetition.makeFormHeadlineFRSelector()
    );
    const subheadlineFR = yield select(
      SelectorCompetition.makeFormSubHeadlineFRSelector()
    );
    const prizeFR = yield select(SelectorCompetition.makeFormPrizeFRSelector());
    const headerFR = yield select(
      SelectorCompetition.makeFormHeaderFRSelector()
    );
    const descriptionFR = yield select(
      SelectorCompetition.makeFormDescriptionFRSelector()
    );
    const titlequestionFR = yield select(
      SelectorCompetition.makeFormTitleQuestionFRSelector()
    );
    const questionFR = yield select(
      SelectorCompetition.makeFormQuestionFRSelector()
    );
    const answer1FR = yield select(
      SelectorCompetition.makeFormAnswer1FRSelector()
    );
    const answer2FR = yield select(
      SelectorCompetition.makeFormAnswer2FRSelector()
    );
    const answer3FR = yield select(
      SelectorCompetition.makeFormAnswer3FRSelector()
    );
    const headlineIT = yield select(
      SelectorCompetition.makeFormHeadlineITSelector()
    );
    const subheadlineIT = yield select(
      SelectorCompetition.makeFormSubHeadlineITSelector()
    );
    const prizeIT = yield select(SelectorCompetition.makeFormPrizeITSelector());
    const headerIT = yield select(
      SelectorCompetition.makeFormHeaderITSelector()
    );
    const descriptionIT = yield select(
      SelectorCompetition.makeFormDescriptionITSelector()
    );
    const titlequestionIT = yield select(
      SelectorCompetition.makeFormTitleQuestionITSelector()
    );
    const questionIT = yield select(
      SelectorCompetition.makeFormQuestionITSelector()
    );
    const answer1IT = yield select(
      SelectorCompetition.makeFormAnswer1ITSelector()
    );
    const answer2IT = yield select(
      SelectorCompetition.makeFormAnswer2ITSelector()
    );
    const answer3IT = yield select(
      SelectorCompetition.makeFormAnswer3ITSelector()
    );
    const answer = yield select(SelectorCompetition.makeFormAnswerSelector());
    const uppyCompetitionLogo = yield select(
      SelectorUppy.makeCompetitionLogoSelector()
    );
    const uppyCompetitionBanner = yield select(
      SelectorUppy.makeCompetitionBannerSelector()
    );
    const uppyCompetitionBackground = yield select(
      SelectorUppy.makeCompetitionBackgroundSelector()
    );

    let logo = null;
    let banner = null;
    let background = null;
    if (uppyCompetitionLogo.files) {
      const competitionLogo = uppyCompetitionLogo.files;
      for (const key in competitionLogo) {
        if (competitionLogo.hasOwnProperty(key)) {
          logo = competitionLogo[key].meta;
        }
      }
    }
    if (uppyCompetitionBanner.files) {
      const competitionBanner = uppyCompetitionBanner.files;
      for (const key in competitionBanner) {
        if (competitionBanner.hasOwnProperty(key)) {
          banner = competitionBanner[key].meta;
        }
      }
    }
    if (uppyCompetitionBackground.files) {
      const competitionBackground = uppyCompetitionBackground.files;
      for (const key in competitionBackground) {
        if (competitionBackground.hasOwnProperty(key)) {
          background = competitionBackground[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/competition`,
      {
        name,
        answer,
        closedate: moment(closedate).format('YYYY-MM-DD'),
        logo,
        banner,
        background,
        headlineEN,
        subheadlineEN,
        prizeEN,
        headerEN,
        descriptionEN,
        titlequestionEN,
        questionEN,
        answer1EN,
        answer2EN,
        answer3EN,
        headlineDE,
        subheadlineDE,
        prizeDE,
        headerDE,
        descriptionDE,
        titlequestionDE,
        questionDE,
        answer1DE,
        answer2DE,
        answer3DE,
        headlineFR,
        subheadlineFR,
        prizeFR,
        headerFR,
        descriptionFR,
        titlequestionFR,
        questionFR,
        answer1FR,
        answer2FR,
        answer3FR,
        headlineIT,
        subheadlineIT,
        prizeIT,
        headerIT,
        descriptionIT,
        titlequestionIT,
        questionIT,
        answer1IT,
        answer2IT,
        answer3IT,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Competition'));
    yield put(ActionCompetition.resetForm());
    yield put(ActionCompetition.fetchCompetitionListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyCompetitionLogo.reset();
    uppy.uppyCompetitionBanner.reset();
    uppy.uppyCompetitionBackground.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerCompetitionSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateCompetitionProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const selectedCompetitionData = yield select(
      SelectorCompetition.makeCompetitionDetailSelector()
    );
    const id = selectedCompetitionData.id;
    const name = yield select(SelectorCompetition.makeFormNameSelector());
    const closedate = yield select(
      SelectorCompetition.makeFormCloseDateSelector()
    );
    const headlineEN = yield select(
      SelectorCompetition.makeFormHeadlineENSelector()
    );
    const subheadlineEN = yield select(
      SelectorCompetition.makeFormSubHeadlineENSelector()
    );
    const prizeEN = yield select(SelectorCompetition.makeFormPrizeENSelector());
    const headerEN = yield select(
      SelectorCompetition.makeFormHeaderENSelector()
    );
    const descriptionEN = yield select(
      SelectorCompetition.makeFormDescriptionENSelector()
    );
    const titlequestionEN = yield select(
      SelectorCompetition.makeFormTitleQuestionENSelector()
    );
    const questionEN = yield select(
      SelectorCompetition.makeFormQuestionENSelector()
    );
    const answer1EN = yield select(
      SelectorCompetition.makeFormAnswer1ENSelector()
    );
    const answer2EN = yield select(
      SelectorCompetition.makeFormAnswer2ENSelector()
    );
    const answer3EN = yield select(
      SelectorCompetition.makeFormAnswer3ENSelector()
    );
    const headlineDE = yield select(
      SelectorCompetition.makeFormHeadlineDESelector()
    );
    const subheadlineDE = yield select(
      SelectorCompetition.makeFormSubHeadlineDESelector()
    );
    const prizeDE = yield select(SelectorCompetition.makeFormPrizeDESelector());
    const headerDE = yield select(
      SelectorCompetition.makeFormHeaderDESelector()
    );
    const descriptionDE = yield select(
      SelectorCompetition.makeFormDescriptionDESelector()
    );
    const titlequestionDE = yield select(
      SelectorCompetition.makeFormTitleQuestionDESelector()
    );
    const questionDE = yield select(
      SelectorCompetition.makeFormQuestionDESelector()
    );
    const answer1DE = yield select(
      SelectorCompetition.makeFormAnswer1DESelector()
    );
    const answer2DE = yield select(
      SelectorCompetition.makeFormAnswer2DESelector()
    );
    const answer3DE = yield select(
      SelectorCompetition.makeFormAnswer3DESelector()
    );
    const headlineFR = yield select(
      SelectorCompetition.makeFormHeadlineFRSelector()
    );
    const subheadlineFR = yield select(
      SelectorCompetition.makeFormSubHeadlineFRSelector()
    );
    const prizeFR = yield select(SelectorCompetition.makeFormPrizeFRSelector());
    const headerFR = yield select(
      SelectorCompetition.makeFormHeaderFRSelector()
    );
    const descriptionFR = yield select(
      SelectorCompetition.makeFormDescriptionFRSelector()
    );
    const titlequestionFR = yield select(
      SelectorCompetition.makeFormTitleQuestionFRSelector()
    );
    const questionFR = yield select(
      SelectorCompetition.makeFormQuestionFRSelector()
    );
    const answer1FR = yield select(
      SelectorCompetition.makeFormAnswer1FRSelector()
    );
    const answer2FR = yield select(
      SelectorCompetition.makeFormAnswer2FRSelector()
    );
    const answer3FR = yield select(
      SelectorCompetition.makeFormAnswer3FRSelector()
    );
    const headlineIT = yield select(
      SelectorCompetition.makeFormHeadlineITSelector()
    );
    const subheadlineIT = yield select(
      SelectorCompetition.makeFormSubHeadlineITSelector()
    );
    const prizeIT = yield select(SelectorCompetition.makeFormPrizeITSelector());
    const headerIT = yield select(
      SelectorCompetition.makeFormHeaderITSelector()
    );
    const descriptionIT = yield select(
      SelectorCompetition.makeFormDescriptionITSelector()
    );
    const titlequestionIT = yield select(
      SelectorCompetition.makeFormTitleQuestionITSelector()
    );
    const questionIT = yield select(
      SelectorCompetition.makeFormQuestionITSelector()
    );
    const answer1IT = yield select(
      SelectorCompetition.makeFormAnswer1ITSelector()
    );
    const answer2IT = yield select(
      SelectorCompetition.makeFormAnswer2ITSelector()
    );
    const answer3IT = yield select(
      SelectorCompetition.makeFormAnswer3ITSelector()
    );
    const answer = yield select(SelectorCompetition.makeFormAnswerSelector());
    const uppyCompetitionLogo = yield select(
      SelectorUppy.makeCompetitionLogoSelector()
    );
    const uppyCompetitionBanner = yield select(
      SelectorUppy.makeCompetitionBannerSelector()
    );
    const uppyCompetitionBackground = yield select(
      SelectorUppy.makeCompetitionBackgroundSelector()
    );

    let logo = null;
    let banner = null;
    let background = null;
    if (uppyCompetitionLogo.files) {
      const competitionLogo = uppyCompetitionLogo.files;
      for (const key in competitionLogo) {
        if (competitionLogo.hasOwnProperty(key)) {
          logo = competitionLogo[key].meta;
        }
      }
    }
    if (uppyCompetitionBanner.files) {
      const competitionBanner = uppyCompetitionBanner.files;
      for (const key in competitionBanner) {
        if (competitionBanner.hasOwnProperty(key)) {
          banner = competitionBanner[key].meta;
        }
      }
    }
    if (uppyCompetitionBackground.files) {
      const competitionBackground = uppyCompetitionBackground.files;
      for (const key in competitionBackground) {
        if (competitionBackground.hasOwnProperty(key)) {
          background = competitionBackground[key].meta;
        }
      }
    }
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/competition/${id}`,
      {
        name,
        answer,
        closedate: moment(closedate).format('YYYY-MM-DD'),
        logo,
        banner,
        background,
        headlineEN,
        subheadlineEN,
        prizeEN,
        headerEN,
        descriptionEN,
        titlequestionEN,
        questionEN,
        answer1EN,
        answer2EN,
        answer3EN,
        headlineDE,
        subheadlineDE,
        prizeDE,
        headerDE,
        descriptionDE,
        titlequestionDE,
        questionDE,
        answer1DE,
        answer2DE,
        answer3DE,
        headlineFR,
        subheadlineFR,
        prizeFR,
        headerFR,
        descriptionFR,
        titlequestionFR,
        questionFR,
        answer1FR,
        answer2FR,
        answer3FR,
        headlineIT,
        subheadlineIT,
        prizeIT,
        headerIT,
        descriptionIT,
        titlequestionIT,
        questionIT,
        answer1IT,
        answer2IT,
        answer3IT,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Competition'));
    yield put(ActionCompetition.resetForm());
    yield put(ActionCompetition.fetchCompetitionListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyCompetitionLogo.reset();
    uppy.uppyCompetitionBanner.reset();
    uppy.uppyCompetitionBackground.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateCompetitionSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteCompetitionProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());

    const id = yield select(
      SelectorCompetition.makeCompetitionSelectedSelector()
    );

    yield call(axios.delete, `${API_URL}/competition/${id}`, config);
    yield put(ActionCompetition.fetchCompetitionListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteCompetitionSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* setCompetitionStatusProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = action.id;
    const status = action.status;
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.get,
      `${API_URL}/competition/${id}/${
        status === true ? 'activate' : 'deactivate'
      }`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="changeCompetitionStatusSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionCompetition.fetchCompetitionListRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getCompetitionListAction() {
  yield takeLatest(
    'FETCH_COMPETITION_LIST_REQUESTED',
    getCompetitionListProcess
  );
}
export function* getCompetitionDetailAction() {
  yield takeLatest(
    'FETCH_COMPETITION_DETAIL_REQUESTED',
    getCompetitionDetailProcess
  );
}
export function* submitNewCompetitionAction() {
  yield takeLatest(
    'REGISTER_COMPETITION_REQUESTED',
    submitNewCompetitionProcess
  );
}
export function* updateCompetitionAction() {
  yield takeLatest('UPDATE_COMPETITION_REQUESTED', updateCompetitionProcess);
}
export function* deleteCompetitionAction() {
  yield takeLatest('DELETE_COMPETITION_REQUESTED', deleteCompetitionProcess);
}
export function* setCompetitionStatusAction() {
  yield takeLatest(
    'SET_STATUS_COMPETITION_REQUESTED',
    setCompetitionStatusProcess
  );
}
