import { Breadcrumb, Result } from 'antd';

import React from 'react';

export default function DashboardComponent() {
  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <Result
          status="500"
          title="Hello"
          subTitle="Please move to another page"
        />
      </div>
    </>
  );
}
