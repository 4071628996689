import * as ActionApp from '../../App/Store/AppAction';
import * as ActionWinner from '../Store/WinnerAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorUppy from '../../../App/Uppy/UppySelector';
import * as SelectorWinner from '../Selector/WinnerSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getWinnerListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/winner`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(
      ActionWinner.fetchWinnerListFinished(data.winner ? data.winner : [])
    );
    yield put(ActionWinner.setWinnerPageDescription(data.winnerpage));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getWinnerDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const url = yield select(SelectorWinner.makeWinnerSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/winner/${url}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionWinner.fetchWinnerDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* submitNewWinnerProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const idcompetition = yield select(
      SelectorWinner.makeFormCompetitionSelector()
    );
    const winner = yield select(SelectorWinner.makeFormWinnerSelector());
    const uppyWinnerPicture = yield select(
      SelectorUppy.makeCompetitionWinnerSelector()
    );
    let picture = null;

    if (uppyWinnerPicture.files) {
      const competitionWinner = uppyWinnerPicture.files;
      for (const key in competitionWinner) {
        if (competitionWinner.hasOwnProperty(key)) {
          picture = competitionWinner[key].meta;
        }
      }
    }

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/winner`,
      {
        idcompetition,
        winner,
        picture,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Winner'));
    yield put(ActionWinner.resetForm());
    yield put(ActionWinner.fetchWinnerListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyWiner.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="registerWinnerSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* updateWinnerProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const selectedWinnerData = yield select(
      SelectorWinner.makeWinnerDetailSelector()
    );
    const id = selectedWinnerData.id;
    const idcompetition = yield select(
      SelectorWinner.makeFormCompetitionSelector()
    );
    const winner = yield select(SelectorWinner.makeFormWinnerSelector());
    const uppyWinnerPicture = yield select(
      SelectorUppy.makeCompetitionWinnerSelector()
    );
    let picture = null;

    if (uppyWinnerPicture.files) {
      const competitionWinner = uppyWinnerPicture.files;
      for (const key in competitionWinner) {
        if (competitionWinner.hasOwnProperty(key)) {
          picture = competitionWinner[key].meta;
        }
      }
    }

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/winner/${id}`,
      {
        idcompetition,
        winner,
        picture,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('Winner'));
    yield put(ActionWinner.resetForm());
    yield put(ActionWinner.fetchWinnerListRequested());
    const uppy = require('../../../App/Uppy/UppyStore');
    uppy.uppyWiner.reset();
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateWinnerSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* updateWinnerDescriptionProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());

    const text1EN = yield select(SelectorWinner.makeFormText1ENSelector());
    const text2EN = yield select(SelectorWinner.makeFormText2ENSelector());
    const text1DE = yield select(SelectorWinner.makeFormText1DESelector());
    const text2DE = yield select(SelectorWinner.makeFormText2DESelector());
    const text1FR = yield select(SelectorWinner.makeFormText1FRSelector());
    const text2FR = yield select(SelectorWinner.makeFormText2FRSelector());
    const text1IT = yield select(SelectorWinner.makeFormText1ITSelector());
    const text2IT = yield select(SelectorWinner.makeFormText2ITSelector());
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/winner`,
      {
        text1EN,
        text2EN,
        text1DE,
        text2DE,
        text1FR,
        text2FR,
        text1IT,
        text2IT,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('WinnerDescription'));
    yield put(ActionWinner.resetFormDescription());
    yield put(ActionWinner.fetchWinnerListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="updateWinnerDescriptionSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteWinnerProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());

    const id = yield select(SelectorWinner.makeWinnerSelectedSelector());

    yield call(axios.delete, `${API_URL}/winner/${id}`, config);
    yield put(ActionWinner.fetchWinnerListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteWinnerSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* setWinnerStatusProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = action.id;
    const status = action.status;
    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.get,
      `${API_URL}/winner/${id}/${status === true ? 'activate' : 'deactivate'}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="changeWinnerStatusSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionWinner.fetchWinnerListRequested());
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}
export function* getWinnerListAction() {
  yield takeLatest('FETCH_WINNER_LIST_REQUESTED', getWinnerListProcess);
}
export function* getWinnerDetailAction() {
  yield takeLatest('FETCH_WINNER_DETAIL_REQUESTED', getWinnerDetailProcess);
}
export function* submitNewWinnerAction() {
  yield takeLatest('REGISTER_WINNER_REQUESTED', submitNewWinnerProcess);
}
export function* updateWinnerAction() {
  yield takeLatest('UPDATE_WINNER_REQUESTED', updateWinnerProcess);
}
export function* updateWinnerDescriptionAction() {
  yield takeLatest(
    'UPDATE_WINNER_DESCRIPTION_REQUESTED',
    updateWinnerDescriptionProcess
  );
}
export function* deleteWinnerAction() {
  yield takeLatest('DELETE_WINNER_REQUESTED', deleteWinnerProcess);
}
export function* setWinnerStatusAction() {
  yield takeLatest('SET_STATUS_WINNER_REQUESTED', setWinnerStatusProcess);
}
