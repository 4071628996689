const WinnerAddValidation = (values, props) => {
  const errors: any = {};
  if (!values.competition) {
    errors.competition = 'Competition required!';
  }
  if (!values.winner) {
    errors.winner = 'Winner required!';
  }
  return errors;
};
export default WinnerAddValidation;
