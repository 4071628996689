import { Layout, Row } from 'antd';

import DashboardComponent from '../Component/DashboardComponent';
import React from 'react';
const { Content } = Layout;

export default function DashboardContainer() {
  return (
    <>
      <Content className="containerContent">
        <Row>
          <DashboardComponent />
        </Row>
      </Content>
    </>
  );
}
