import { Breadcrumb, Button, Col, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../Assets/Components/CButton';
import CTable from '../../../Assets/Components/CTable';
import { InputSelect } from '../../../Assets/Components/CInput';
import ModalParticipantContainer from '../Container/ModalParticipantContainer';
import React from 'react';
import { injectIntl } from 'react-intl';

function FormParticipantComponent(props) {
  const {
    columns,
    loading,
    list,
    handleRefresh,
    intl,
    competitionList,
    handleDownloadReport,
  } = props;
  const buttonData = [
    {
      type: 'default',
      icon: 'reload',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefresh',
    },
  ];
  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Participant</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={loading}
        />
        <Row gutter={30}>
          <Col span={18}>
            <Field
              name="competition"
              component={InputSelect}
              data={competitionList}
              idComponent="inputCompetition"
              label={intl.formatMessage({
                id: 'competition',
              })}
            />
          </Col>
          <Col span={6}>
            <div className="colBtnSearchPeriod">
              <Button
                block
                icon="search"
                onClick={handleRefresh}
                size="large"
                type="primary"
                loading={loading}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <CTable
          data={list}
          columns={columns}
          minRows={0}
          defaultPageSize={10}
          isLoading={loading}
          pagination={true}
          useFilterGlobal={true}
        />
        <Row gutter={20} className="rowDownloadReport">
          <Col span={24}>
            <Button
              onClick={handleDownloadReport}
              icon="download"
              type="primary"
              loading={loading}
              id="btnDownloadTRackerReport"
              disabled={list.length === 0}
            >
              Download report
            </Button>
          </Col>
        </Row>
        <ModalParticipantContainer />
      </div>
    </>
  );
}
const ParticipantComponent = reduxForm({
  form: 'filterCompetition',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
})(injectIntl(FormParticipantComponent));
export default ParticipantComponent;
