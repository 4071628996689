import React from 'react';
import { Result } from 'antd';

export default function NotFoundComponent() {
  return (
    <>
      <div className="site-layout-content">
        <Result
          status="404"
          title="Page not found"
          subTitle="Please move to another page"
        />
      </div>
    </>
  );
}
