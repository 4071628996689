import * as ActionApp from '../../App/Store/AppAction';
import * as ActionParticipant from '../Store/ParticipantAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorParticipant from '../Selector/ParticipantSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getParticipantListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const competition = yield select(
      SelectorParticipant.makeFormCompetitionSelector()
    );
    if (competition) {
      yield put(ActionsComponent.processLoading(true));
      const { data } = yield call(
        axios.get,
        `${API_URL}/participant/${competition}`,
        config
      );
      yield put(ActionsComponent.processLoading(false));
      yield put(ActionParticipant.fetchParticipantListFinished(data));
    }
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getParticipantDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(
      SelectorParticipant.makeParticipantSelectedSelector()
    );
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/participant/detail/${id}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionParticipant.fetchParticipantDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* deleteParticipantProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());

    const id = yield select(
      SelectorParticipant.makeParticipantSelectedSelector()
    );

    yield call(axios.delete, `${API_URL}/participant/${id}`, config);
    yield put(ActionParticipant.fetchParticipantListRequested());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="deleteParticipantSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* generateReportProcess(action) {
  try {
    const config = yield call(getCommonConfigHeader);
    const token = yield select(SelectorAuth.makeTokenSelector());
    const id = yield select(SelectorParticipant.makeFormCompetitionSelector());
    const API_URL = yield select(SelectorConfig.makeBaseAPISelector());

    yield put(ActionsComponent.processLoading(true));
    axios({
      url: `${API_URL}/participant/${id}/generatecsv`,
      method: 'GET',
      responseType: 'blob',
      config,
      headers: { Authorization: `bearer ${token}` },
    }).then(response => {
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const now = new Date();
      const day = now.getDay();
      const month = now.getMonth();
      const year = now.getFullYear();
      link.href = urlDownload;
      link.setAttribute(
        'download',
        `Participant-Report-${day}-${month}-${year}.csv`
      );
      document.body.appendChild(link);
      link.click();
    });
    yield put(ActionsComponent.processLoading(false));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
    yield put(ActionsComponent.processLoading(false));
  }
}

export function* getParticipantListAction() {
  yield takeLatest(
    'FETCH_PARTICIPANT_LIST_REQUESTED',
    getParticipantListProcess
  );
}
export function* getParticipantDetailAction() {
  yield takeLatest(
    'FETCH_PARTICIPANT_DETAIL_REQUESTED',
    getParticipantDetailProcess
  );
}
export function* deleteParticipantAction() {
  yield takeLatest('DELETE_PARTICIPANT_REQUESTED', deleteParticipantProcess);
}
export function* generateReportAction() {
  yield takeLatest(
    'DOWNLOAD_REPORT_PARTICIPANT_REQUESTED',
    generateReportProcess
  );
}
