import {
  IFetchWinnerDetailSuccessAction,
  IFetchWinnerListSuccessAction,
  ISetModalDataAction,
  ISetSelectedWinnerAction,
  ISetWinnerPageDescriptionAction,
} from './WinnerAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedWinner: '',
  selectedWinnerData: {},
  winnerPageDescription: {},
  additionalExpenseModalAction: 'register',
};
export default function WinnerReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_WINNER_LIST_FINISHED': {
      const fetchWinnerListSuccessAction = action as IFetchWinnerListSuccessAction;
      newState.list = fetchWinnerListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_WINNER_DETAIL_FINISHED': {
      const fetchWinnerDetailSuccessAction = action as IFetchWinnerDetailSuccessAction;
      newState.selectedWinnerData = fetchWinnerDetailSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_WINNER': {
      const setSelectedWinnerAction = action as ISetSelectedWinnerAction;
      newState.selectedWinner = setSelectedWinnerAction.id;
      return { ...newState };
    }
    case 'SET_WINNER_PAGE_DESCRIPTION': {
      const setWinnerPageDescriptionAction = action as ISetWinnerPageDescriptionAction;
      newState.winnerPageDescription = setWinnerPageDescriptionAction.data;
      return { ...newState };
    }
    case 'SET_WINNER_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.additionalExpenseModalAction = setModalDataAction.action;
      newState.selectedWinnerData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
