import { Action } from 'redux';
export interface IFetchCompetitionListSuccessAction extends Action {
  data: object;
}
export interface IFetchCompetitionDetailSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedCompetitionAction extends Action {
  id: string;
}
export interface ISetModalDataAction extends Action {
  action: string;
  data: object;
}
export interface ISetStatusCompetitionAction extends Action {
  id: string;
  status: boolean;
}
export function registerCompetitionRequested(): Action {
  return {
    type: 'REGISTER_COMPETITION_REQUESTED',
  };
}
export function updateCompetitionRequested(): Action {
  return {
    type: 'UPDATE_COMPETITION_REQUESTED',
  };
}
export function fetchCompetitionListRequested(): Action {
  return {
    type: 'FETCH_COMPETITION_LIST_REQUESTED',
  };
}
export function fetchCompetitionDetailRequested(): Action {
  return {
    type: 'FETCH_COMPETITION_DETAIL_REQUESTED',
  };
}
export function setSelectedCompetition(
  id: string
): ISetSelectedCompetitionAction {
  return {
    type: 'SET_SELECTED_COMPETITION',
    id,
  };
}
export function fetchCompetitionListFinished(
  data: object
): IFetchCompetitionListSuccessAction {
  return {
    type: 'FETCH_COMPETITION_LIST_FINISHED',
    data,
  };
}
export function fetchCompetitionDetailFinished(
  data: object
): IFetchCompetitionListSuccessAction {
  return {
    type: 'FETCH_COMPETITION_DETAIL_FINISHED',
    data,
  };
}
export function setCompetitionModalData(action, data): ISetModalDataAction {
  return {
    type: 'SET_COMPETITION_MODAL_DATA',
    action,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_COMPETITION',
  };
}
export function deleteCompetitionRequested() {
  return {
    type: 'DELETE_COMPETITION_REQUESTED',
  };
}
export function setStatusCompetitionRequested(
  id: string,
  status: boolean
): ISetStatusCompetitionAction {
  return {
    type: 'SET_STATUS_COMPETITION_REQUESTED',
    id,
    status,
  };
}
