import 'react-toastify/dist/ReactToastify.css';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthMiddleware from './AuthMiddleware';
import CompetitionContainer from '../Modules/Competition/Container/CompetitionContainer';
import DashboardContainer from '../Modules/Dashboard/Container/DashboardContainer';
import { Helmet } from 'react-helmet';
import LoginContainer from './../Modules/Auth/Container/LoginContainer';
import NotFoundComponent from '../Modules/App/Component/NotFoundComponent';
import ParticipantContainer from '../Modules/Participant/Container/ParticipantContainer';
import ReduxToastr from 'react-redux-toastr';
import TemplateContainer from '../Modules/Template/Container/TemplateContainer';
import { ToastContainer } from 'react-toastify';
import WinnerContainer from '../Modules/Winner/Container/WinnerContainer';
import WithTemplate from '../App/WithTemplate';

export default class ApplicationComponent extends Component {
  protected login;
  protected index;
  protected competition;
  protected participant;
  protected winner;

  render() {
    this.login = LoginContainer;
    this.index = AuthMiddleware(
      WithTemplate(TemplateContainer, DashboardContainer)
    );
    this.competition = AuthMiddleware(
      WithTemplate(TemplateContainer, CompetitionContainer)
    );
    this.participant = AuthMiddleware(
      WithTemplate(TemplateContainer, ParticipantContainer)
    );
    this.winner = AuthMiddleware(
      WithTemplate(TemplateContainer, WinnerContainer)
    );
    return (
      <div>
        <Helmet
          titleTemplate="Content Management System"
          defaultTitle="Content Management System"
        >
          <meta name="description" content="Content Management System" />
        </Helmet>
        <ReduxToastr
          timeOut={0}
          newestOnTop={false}
          preventDuplicates={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={true}
          closeOnToastrClick={true}
        />
        <ToastContainer autoClose={2000} />
        <Switch>
          <Route
            exact={true}
            path={`${process.env.PUBLIC_URL}/login`}
            component={this.login}
          />
          <Route
            exact={true}
            path={`${process.env.PUBLIC_URL}/`}
            component={this.index}
          />
          <Route
            exact={true}
            path={`${process.env.PUBLIC_URL}/competition`}
            component={this.competition}
          />
          <Route
            exact={true}
            path={`${process.env.PUBLIC_URL}/participant`}
            component={this.participant}
          />
          {/* <Route
            exact={true}
            path={`${process.env.PUBLIC_URL}/winner`}
            component={this.winner}
          /> */}
          <Route path={'*'} component={NotFoundComponent} />
        </Switch>
      </div>
    );
  }
}
