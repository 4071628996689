import { Action } from 'redux';
export interface IFetchParticipantListSuccessAction extends Action {
  data: object;
}
export interface IFetchParticipantDetailSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedParticipantAction extends Action {
  id: string;
}
export interface ISetSelectedParticipantDetailAction extends Action {
  data: object;
}

export function fetchParticipantListRequested(): Action {
  return {
    type: 'FETCH_PARTICIPANT_LIST_REQUESTED',
  };
}
export function downloadReportParticipantRequested(): Action {
  return {
    type: 'DOWNLOAD_REPORT_PARTICIPANT_REQUESTED',
  };
}
export function fetchParticipantDetailRequested(): Action {
  return {
    type: 'FETCH_PARTICIPANT_DETAIL_REQUESTED',
  };
}
export function setSelectedParticipant(
  id: string
): ISetSelectedParticipantAction {
  return {
    type: 'SET_SELECTED_PARTICIPANT',
    id,
  };
}
export function fetchParticipantListFinished(
  data: object
): IFetchParticipantListSuccessAction {
  return {
    type: 'FETCH_PARTICIPANT_LIST_FINISHED',
    data,
  };
}
export function fetchParticipantDetailFinished(
  data: object
): IFetchParticipantListSuccessAction {
  return {
    type: 'FETCH_PARTICIPANT_DETAIL_FINISHED',
    data,
  };
}
export function setSelectedParticipantDetail(
  data
): IFetchParticipantDetailSuccessAction {
  return {
    type: 'SET_SELECTED_PARTICIPANT_DETAIL',
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_PARTICIPANT',
  };
}
export function deleteParticipantRequested() {
  return {
    type: 'DELETE_PARTICIPANT_REQUESTED',
  };
}
