import { createSelector } from 'reselect';
const selectorComponent = state => state.ComponentState;
const selectorAppConfig = state => state.ConfigAppState;

export const makeBaseAPISelector = () =>
  createSelector(selectorAppConfig, state => state.appConfig.baseAPI);
export const makeSiderIsColapseSelector = () =>
  createSelector(selectorComponent, state => state.siderIsColapse);
export const makeIsLoading = () =>
  createSelector(selectorComponent, state => state.isLoading);

export const userModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalUser);
export const participantModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalParticipant);
export const competitionModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalCompetition);
export const winnerModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalWinner);
export const winnerDescriptionModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalWinnerDescription);
export const changePasswordModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalChangePassword);
export const makeLightBoxIsOpen = component =>
  createSelector(
    selectorComponent,
    state => state[`lightBox${component}IsOpen`]
  );
