import * as AuthActions from '../../Auth/Store/AuthAction';
import * as ComponentActions from '../../App/Store/ComponentAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';

import LoginComponent from '../Component/LoginComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from '../../../App/History';
import { actions as toastrActions } from 'react-redux-toastr';

function LoginContainer(props) {
  const { authAction, isAuthenticated } = props;
  const submitLogin = () => {
    authAction.submitLogin();
  };

  if (isAuthenticated) {
    history.push(`/`);
  }

  return <LoginComponent submitLogin={submitLogin} {...props} />;
}

const mapStateToProps = createStructuredSelector({
  isLoading: SelectorsComponent.makeIsLoading(),
  isAuthenticated: SelectorsAuth.makeTokenSelector(),
});

const mapDispatchToProps = dispatch => ({
  redirect: () => history.push('/login'),
  componentAction: bindActionCreators(ComponentActions, dispatch),
  authAction: bindActionCreators(AuthActions, dispatch),
  toastR: bindActionCreators(toastrActions.showConfirm, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LoginContainer);
