import * as ActionCompetition from '../../Competition/Store/CompetitionAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionParticipant from '../Store/ParticipantAction';
import * as SelectorsCompetition from '../../Competition/Selector/CompetitionSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsParticipant from '../Selector/ParticipantSelector';

import { Layout, Row } from 'antd';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import { FormattedMessage } from 'react-intl';
import ParticipantComponent from '../Component/ParticipantComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function ParticipantContainer(props) {
  const { participantAction, componentAction, competitionAction } = props;

  useEffect(() => {
    competitionAction.fetchCompetitionListRequested();
  }, [competitionAction]);

  const handleAddParticipant = () => {
    participantAction.setParticipantModalData('register');
    componentAction.openModal('Participant');
  };

  const handleRefresh = () => {
    participantAction.fetchParticipantListRequested();
  };

  const handleDownloadReport = () => {
    participantAction.downloadReportParticipantRequested();
  };
  const renderAction = row => {
    const editClick = () => {
      participantAction.setSelectedParticipant(row.row.original.id);
      participantAction.fetchParticipantDetailRequested();
      componentAction.openModal('Participant');
    };
    const deleteClick = () => {
      const toastrConfirmOptions = {
        onOk: () => {
          participantAction.setSelectedParticipant(row.row.original.id);
          participantAction.deleteParticipantRequested();
        },
      };
      toastr.confirm(
        `Are you sure to delete "${row.row.original.firstname}"?`,
        toastrConfirmOptions
      );
    };
    const buttonData = [
      {
        type: 'primary',
        icon: 'info',
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailParticipant-${row.row.index + 1}`,
        tooltipTitle: 'Info',
      },
      {
        type: 'danger',
        icon: 'delete',
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteParticipant-${row.row.index + 1}`,
        tooltipTitle: 'Delete',
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton
          buttonFloat="none"
          buttonData={buttonData}
          containerStyle={{ marginBottom: 0 }}
        />
      </div>
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: 'First Name',
      accessor: 'firstname',
    },
    {
      Header: 'Middle Name',
      accessor: 'middlename',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Language Code',
      accessor: 'language',
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      headerClassName: 'headerDatatableClassCenter',
      Header: () => <FormattedMessage id="action" />,
      accessor: 'action',
      Cell: renderAction,
      width: 100,
      disableSort: true,
    },
  ];
  return (
    <React.Fragment>
      <Content className="containerContent">
        <Row>
          <ParticipantComponent
            columns={columns}
            handleAddParticipant={handleAddParticipant}
            handleRefresh={handleRefresh}
            handleDownloadReport={handleDownloadReport}
            {...props}
          />
        </Row>
      </Content>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  competitionList: SelectorsCompetition.makeCompetitionListSelector(),
  list: SelectorsParticipant.makeParticipantListSelector(),
  loading: SelectorsComponent.makeIsLoading(),
  participantModalIsShow: SelectorsComponent.participantModalIsShow(),
});

const mapDispatchToProps = dispatch => ({
  participantAction: bindActionCreators(ActionParticipant, dispatch),
  competitionAction: bindActionCreators(ActionCompetition, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ParticipantContainer);
