import * as ActionComponent from '../../Modules/App/Store/ComponentAction';
import * as SelectorsComponent from '../../Modules/App/Selector/AppSelector';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import Lightbox from 'react-image-lightbox';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

interface IProps {
  images;
  isOpen?;
  componentAction?;
  component;
  folder;
}

function ImageLightbox(props: IProps) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const { images, isOpen, componentAction, component } = props;
  if (isOpen) {
    return (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => componentAction.openLightBox(component)}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % images.length)
        }
      />
    );
  }
  return null;
}

const mapStateToProps = (state, props) => {
  return createStructuredSelector({
    isOpen: SelectorsComponent.makeLightBoxIsOpen(props.component),
  });
};

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const CImageLightbox = compose(withConnect)(ImageLightbox);
export default CImageLightbox;

export const showImagePreview = (
  value,
  component,
  componentAction,
  token,
  folder
) => {
  if (value) {
    const renderImg = vImage => {
      let dataImg = vImage;
      const dataArrPrev: any[] = [];
      if (typeof vImage === 'string') {
        dataImg = [{ id: 'idKey', media: vImage }];
      }
      const img = dataImg.map((val, idx) => {
        dataArrPrev.push(
          `${process.env.REACT_APP_API_URL}/image/${folder}/${val.media}?token=${token}`
        );
        return (
          <img
            key={`imgPreviewUppy${idx}`}
            className="imgPreviewUppy"
            alt="Deleted!"
            src={`${process.env.REACT_APP_API_URL}/image/${folder}/${val.media}?token=${token}`}
            onClick={() => componentAction.openLightBox(component)}
          />
        );
      });
      return (
        <React.Fragment>
          {img}
          <CImageLightbox images={dataArrPrev} component={component} />
        </React.Fragment>
      );
    };
    return (
      <React.Fragment>
        <React.Fragment>{renderImg(value)}</React.Fragment>
      </React.Fragment>
    );
  }
  return null;
};
