import * as ActionCompetition from '../Store/CompetitionAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsCompetition from '../Selector/CompetitionSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';
import {
  uppyCompetitionBackground,
  uppyCompetitionBanner,
  uppyCompetitionLogo,
} from '../../../App/Uppy/UppyStore';

import ModalCompetitionComponent from '../Component/ModalCompetitionComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import moment from 'moment';

function ModalCompetitionContainer(props) {
  const {
    componentAction,
    competitionAction,
    modalCompetitionAction,
    competitionDetail,
  } = props;

  const handleCancel = () => {
    componentAction.openModal('Competition');
    competitionAction.setCompetitionModalData('register');
    uppyCompetitionBackground.reset();
    uppyCompetitionBanner.reset();
    uppyCompetitionLogo.reset();
    competitionAction.resetForm();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (modalCompetitionAction === 'register') {
      competitionAction.registerCompetitionRequested();
    } else {
      competitionAction.updateCompetitionRequested();
    }
  };

  const initialValues: any = {};
  if (
    modalCompetitionAction === 'update' &&
    competitionDetail &&
    Object.keys(competitionDetail).length > 0
  ) {
    const competitionDetailLang = competitionDetail.competition_detail;
    initialValues.name = competitionDetail.name;
    initialValues.logo = competitionDetail.logo;
    initialValues.banner = competitionDetail.banner;
    initialValues.background = competitionDetail.background;
    initialValues.answer = competitionDetail.answer;
    initialValues.closedate = moment(competitionDetail.closedate, 'YYYY-MM-DD');

    for (const iterator of competitionDetailLang) {
      const selectedLang = iterator.language.toUpperCase();
      initialValues[`headline${selectedLang}`] = iterator.headline;
      initialValues[`subheadline${selectedLang}`] = iterator.subheadline;
      initialValues[`prize${selectedLang}`] = iterator.prize;
      initialValues[`header${selectedLang}`] = iterator.header;
      initialValues[`description${selectedLang}`] = iterator.description;
      initialValues[`titlequestion${selectedLang}`] = iterator.titlequestion;
      initialValues[`question${selectedLang}`] = iterator.question;
      initialValues[`answer1${selectedLang}`] = iterator.answer1;
      initialValues[`answer2${selectedLang}`] = iterator.answer2;
      initialValues[`answer3${selectedLang}`] = iterator.answer3;
    }
  }

  return (
    <ModalCompetitionComponent
      initialValues={initialValues}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.makeIsLoading(),
  competitionModalIsShow: SelectorsComponent.competitionModalIsShow(),
  errorFormValue: getFormSyncErrors('competitionForm'),
  competitionDetail: SelectorsCompetition.makeCompetitionDetailSelector(),
  modalCompetitionAction: SelectorsCompetition.makeCompetitionModalActionSelector(),
  token: SelectorsAuth.makeTokenSelector(),
});

const mapDispatchToProps = dispatch => ({
  competitionAction: bindActionCreators(ActionCompetition, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalCompetitionContainer);
