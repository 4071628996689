export const localeUppy: any = {};

export default {
  loginSuccess: 'Login success!',
  action: 'Action',
  placeholderName: 'Type competition name here!',
  competitionForm: 'Competition Form',
  name: 'Name',
  placeholderPrize: 'Type prize here',
  prize: 'Prize',
  placeholderHeader: 'Type header here',
  header: 'Header',
  placeholderDescription: 'Type description here',
  description: 'Description',
  placeholderTitleQuestion: 'Type title question',
  titlequestion: 'Title question',
  placeholderQuestion: 'Type question here',
  question: 'Question',
  placeholderAnswer1: 'Type answer 1 here',
  answer1: 'Answer 1',
  placeholderAnswer2: 'Type answer 2 here',
  answer2: 'Answer 2',
  placeholderAnswer3: 'Type answer 3 here',
  placeholderAnswer: 'Select correct answer',
  answer3: 'Answer 3',
  answer: 'Correct answer',
  inputCompetitionLogo: 'Competition logo',
  inputCompetitionBanner: 'Competition banner',
  inputCompetitionBackground: 'Competition background',
  errorAlertTitle: 'Error List :',
  registerCompetitionSuccess: 'Success add competition',
  status: 'Status',
  unknownError: 'Unkown error, please contact administrator!',
  deleteCompetitionSuccess: 'Success delete competition',
  updateCompetitionSuccess: 'Success update competition',
  deleteParticipantSuccess: 'Success delete participant',
  competition: 'Competition',
  participantForm: 'Participant Form',
  inputText1: 'Text description 1',
  inputText2: 'Text description 2',
  placeholderText1: 'Input text for description 1 here',
  placeholderText2: 'Input text for description 2 here',
  winnerDescriptionForm: 'Winner Page Description',
  winnerForm: 'Winner Form',
  updateWinnerDescriptionSuccess: 'Success update winner description page!',
  deleteWinnerSuccess: 'Success delete winner',
  placeholderwinner: 'Type winner name here',
  winner: 'Winner',
  inputWinnerPicture: 'Winner Picture',
  updateWinnerSuccess: 'Success update winner',
  userChangePasswordForm: 'Change password',
  password: 'Password',
  placeholderOldPassword: 'Type old password here',
  placeholderNewPassword: 'Type new password here',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  placeholderConfirmPassword: 'Type confirm password here',
  closeDate: 'Close date',
  placeholderCloseDate: 'Select close date',
  headline: 'Headline',
  subheadline: 'Subheadline',
  placeholderHeadline: 'Input headline',
  placeholderSubHeadline: 'Input sub headline',
  sessionExpired:
    'Authorization failed. Please login again or check your username password',
  changePasswordSuccess: 'Success change password',
  'name.type.must.unique': 'Name already used! Choose another name!',
  'cannot.same.prev.password': 'Cannot same with previous password',
  'oldpassword.not.match': 'Old password not match with user data',
};

localeUppy.strings = {
  addMoreFiles: 'Add more file',
  addingMoreFiles: 'Add more file',
  allowAccessDescription:
    'To take pictures or record videos with your camera, please allow camera access for this site.',
  allowAccessTitle: 'Please allow access to your camera',
  authenticateWith: 'Conencting to %{pluginName}',
  authenticateWithTitle:
    'Please authentication with %{pluginName} to select file',
  back: 'Back',
  browse: 'search',
  cancel: 'Cancel',
  cancelUpload: 'Cancel upload',
  chooseFiles: 'Select file',
  closeModal: 'Close popup',
  companionAuthError: 'Need authorization',
  companionError: 'Failed connect to Companion',
  complete: 'Complete',
  connectedToInternet: 'Connecting to internet',
  copyLink: 'Copy link',
  copyLinkToClipboardFallback: 'Copy URL below',
  copyLinkToClipboardSuccess: 'Link copied to clipboard',
  creatingAssembly: 'Ready for upload...',
  creatingAssemblyFailed: 'Transloadit: Cannot create Assembly',
  dashboardTitle: 'Uploaded file',
  dashboardWindowTitle: 'File upload section (Press escape to close)',
  dataUploadedOfTotal: '%{complete} of %{total}',
  done: 'Done',
  dropHereOr: 'Drop file here or %{browse}',
  dropHint: 'Drop file here',
  dropPaste: 'Drop file here to upload, or %{browse} file from computer',
  dropPasteImport: 'Drop file here, salin, %{browse} or import from',
  edit: 'Ubah',
  editFile: 'Edit file',
  editing: 'Edit %{file}',
  emptyFolderAdded: 'No file added from empty folder',
  encoding: 'Encoding file...',
  enterCorrectUrl:
    'Incorrect URL: Make sure you enter a direct link to the file',
  enterUrlToImport: 'Enter the URL to import the file',
  exceedsSize: 'This file exceeds the maximum allowable size of',
  failedToFetch: 'Companion failed fetch from this url, please recheck',
  failedToUpload: 'Failed upload %{file}',
  fileSource: 'Source file: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} from %{smart_count} file uploaded',
    '1': '%{complete} from %{smart_count} file uploaded',
    '2': '%{complete} from %{smart_count} file uploaded',
  },
  filter: 'Filter',
  finishEditingFile: 'Finish editing file',
  folderAdded: {
    '0': 'Success add %{smart_count} file from %{folder}',
    '1': 'Success add %{smart_count} file from %{folder}',
    '2': 'Success add %{smart_count} file from %{folder}',
  },
  import: 'Import',
  importFrom: 'Import from %{name}',
  link: 'Link',
  loading: 'Loading...',
  logOut: 'Log out',
  myDevice: 'My device',
  noFilesFound: 'No file found',
  noInternetConnection: 'No internet connection',
  pause: 'Pause',
  pauseUpload: 'Pause upload',
  paused: 'Paused',
  poweredBy: 'Powered by',
  preparingUpload: 'Preparing upload...',
  processingXFiles: {
    '0': 'Processing %{smart_count} file',
    '1': 'Processing %{smart_count} file',
    '2': 'Processing %{smart_count} file',
  },
  removeFile: 'Remove file',
  resetFilter: 'Reset filter',
  resume: 'Resume',
  resumeUpload: 'Continue upload',
  retry: 'Retry',
  retryUpload: 'Retry upload',
  saveChanges: 'Save change',
  selectXFiles: {
    '0': 'Select %{smart_count} file',
    '1': 'Select %{smart_count} file',
    '2': 'Select %{smart_count} file',
  },
  smile: 'Smile!',
  startRecording: 'Start record video',
  stopRecording: 'Stop record video',
  takePicture: 'Pick picture',
  timedOut: 'Upload stop for %{seconds} second, cancel.',
  upload: 'Upload',
  uploadComplete: 'Upload success',
  uploadFailed: 'Failed upload',
  uploadPaused: 'Upload paused',
  uploadXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  uploadXNewFiles: {
    '0': 'Upload +%{smart_count} file',
    '1': 'Upload +%{smart_count} file',
    '2': 'Upload +%{smart_count} file',
  },
  uploading: 'Upload',
  uploadingXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  xFilesSelected: {
    '0': '%{smart_count} file selected',
    '1': '%{smart_count} file selected',
    '2': '%{smart_count} file selected',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} file added',
    '1': '%{smart_count} file added',
    '2': '%{smart_count} file added',
  },
  xTimeLeft: '%{time} left',
  youCanOnlyUploadFileTypes: 'Only can upload: %{types}',
  youCanOnlyUploadX: {
    '0': 'Only can upload %{smart_count} file',
    '1': 'Only can upload %{smart_count} file',
    '2': 'Only can upload %{smart_count} file',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Please select file at least %{smart_count} file',
    '1': 'Please select file at least %{smart_count} file',
    '2': 'Please select file at least %{smart_count} file',
  },
};
