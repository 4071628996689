import * as ActionCompetition from '../Store/CompetitionAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorsCompetition from '../Selector/CompetitionSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { Layout, Row, Switch } from 'antd';
import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import CompetitionComponent from '../Component/CompetitionComponent';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toastr } from 'react-redux-toastr';

const { Content } = Layout;

function CompetitionContainer(props) {
  const { competitionAction, componentAction } = props;

  useEffect(() => {
    competitionAction.fetchCompetitionListRequested();
  }, [competitionAction]);

  const handleAddCompetition = () => {
    competitionAction.setCompetitionModalData('register');
    componentAction.openModal('Competition');
  };

  const handleRefresh = () => {
    competitionAction.fetchCompetitionListRequested();
  };

  const renderAction = row => {
    const editClick = () => {
      competitionAction.setSelectedCompetition(row.row.original.url);
      competitionAction.setCompetitionModalData('update');
      competitionAction.fetchCompetitionDetailRequested();
      componentAction.openModal('Competition');
    };
    const deleteClick = () => {
      const toastrConfirmOptions = {
        onOk: () => {
          competitionAction.setSelectedCompetition(row.row.original.id);
          competitionAction.deleteCompetitionRequested();
        },
      };
      toastr.confirm(
        `Are you sure to delete "${row.row.original.name}"?`,
        toastrConfirmOptions
      );
    };
    const buttonData = [
      {
        type: 'primary',
        icon: 'edit',
        className: 'btnActionDetail',
        onClick: editClick,
        id: `buttonActionDetailCompetition-${row.row.index + 1}`,
        tooltipTitle: 'Edit',
      },
      {
        type: 'danger',
        icon: 'delete',
        className: 'btnActionDetail',
        onClick: deleteClick,
        id: `buttonActionDeleteCompetition-${row.row.index + 1}`,
        tooltipTitle: 'Delete',
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton
          buttonFloat="none"
          buttonData={buttonData}
          containerStyle={{ marginBottom: 0 }}
        />
      </div>
    );
  };
  const renderStatus = row => {
    const handleOnChange = checked => {
      const status = checked ? 'activate' : 'deactivate';
      const toastrConfirmOptions = {
        onOk: () => {
          competitionAction.setStatusCompetitionRequested(
            row.row.original.id,
            checked
          );
        },
      };
      toastr.confirm(
        `Are you sure to ${status} "${row.row.original.name}"?`,
        toastrConfirmOptions
      );
    };
    return (
      <Switch
        defaultChecked={row.row.original.status === 'active'}
        onChange={handleOnChange}
      />
    );
  };
  const columns = [
    {
      Header: 'No',
      accessor: 'No',
      className: 'numberIndexDatatable',
      Cell: row => parseInt(row.row.id, 10) + 1,
      width: 35,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: () => <FormattedMessage id="status" />,
      accessor: 'status',
      Cell: renderStatus,
      width: 20,
    },
    {
      headerClassName: 'headerDatatableClassCenter',
      Header: () => <FormattedMessage id="action" />,
      accessor: 'action',
      Cell: renderAction,
      width: 100,
      disableSort: true,
    },
  ];
  return (
    <React.Fragment>
      <Content className="containerContent">
        <Row>
          <CompetitionComponent
            columns={columns}
            handleAddCompetition={handleAddCompetition}
            handleRefresh={handleRefresh}
            {...props}
          />
        </Row>
      </Content>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsCompetition.makeCompetitionListSelector(),
  loading: SelectorsComponent.makeIsLoading(),
  competitionModalIsShow: SelectorsComponent.competitionModalIsShow(),
});

const mapDispatchToProps = dispatch => ({
  competitionAction: bindActionCreators(ActionCompetition, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CompetitionContainer);
