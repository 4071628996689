import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionWinner from '../Store/WinnerAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsWinner from '../Selector/WinnerSelector';

import { bindActionCreators, compose } from 'redux';

import ModalWinnerDescriptionComponent from '../Component/ModalWinnerDescriptionComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';

function ModalWinnerContainer(props) {
  const { componentAction, winnerAction, winnerPageData } = props;

  const handleCancel = () => {
    componentAction.openModal('WinnerDescription');
    winnerAction.setWinnerModalData('register');
    winnerAction.resetForm();
  };

  const handleSubmit = e => {
    e.preventDefault();
    winnerAction.updateWinnerDescriptionRequested();
  };

  const initialValues: any = {};
  if (winnerPageData && Object.keys(winnerPageData).length > 0) {
    for (const iterator of winnerPageData) {
      const selectedLang = iterator.language.toUpperCase();
      initialValues[`text1${selectedLang}`] = iterator.text1;
      initialValues[`text2${selectedLang}`] = iterator.text2;
    }
  }
  return (
    <ModalWinnerDescriptionComponent
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.makeIsLoading(),
  errorFormValue: getFormSyncErrors('winnerDescriptionForm'),
  winnerPageData: SelectorsWinner.makeWinnerPageDescriptionSelector(),
  winnerDescriptionModalIsShow: SelectorsComponent.winnerDescriptionModalIsShow(),
});

const mapDispatchToProps = dispatch => ({
  winnerAction: bindActionCreators(ActionWinner, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalWinnerContainer);
