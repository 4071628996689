import { Form, Icon, Input, InputNumber, Select, TimePicker } from 'antd';

import React from 'react';
import moment from 'moment';

interface IProps {
  input;
  idComponent;
  placeholder?;
  meta?;
  label?;
  disabled?;
  isLoading?;
}

interface IPropsText extends IProps {
  icon?;
  textArea?;
  labelDesc?;
  maxLength?;
}

interface IPropsTextNumber extends IProps {
  icon?;
  min?;
  max?;
  defaultValue?;
}

interface IPropsSelect extends IProps {
  icon?;
  data;
  dafaultItemName;
  additionalHandleChangeFunc?;
  customKeyValue?;
  customKeyLabel?;
  showSearch?;
  onSearch?;
  filterOption?;
  addLabel?;
  addAction?;
  descriptionValue?;
}

const defaultStyle = { color: 'rgba(0,0,0,.25)' };
const defaultStyleInput = { width: '100%' };

export const InputText = ({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  icon,
  label,
  textArea,
  labelDesc,
  disabled,
  isLoading,
  maxLength,
}: IPropsText) => {
  const handleOnChange = e => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={
        touched && error !== undefined ? 'error' : labelDesc ? 'warning' : ''
      }
      help={touched && error !== undefined ? error : labelDesc ? labelDesc : ''}
      label={labelTitle}
      colon={false}
    >
      {textArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          autoSize={{ minRows: 8 }}
          disabled={disabled}
          maxLength={maxLength}
          loading={isLoading}
        />
      ) : (
        <Input
          placeholder={placeholder}
          prefix={icon ? <Icon type={icon} style={defaultStyle} /> : null}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          disabled={disabled}
          maxLength={maxLength}
          loading={isLoading}
        />
      )}
    </Form.Item>
  );
};
export const InputTextNumber = ({
  input,
  placeholder,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  min,
  max,
  defaultValue,
  disabled,
}: IPropsTextNumber) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <InputNumber
        style={defaultStyleInput}
        placeholder={placeholder}
        prefix={icon ? <Icon type={icon} style={defaultStyle} /> : null}
        id={idComponent}
        min={min}
        max={max}
        defaultValue={defaultValue}
        disabled={disabled}
        {...input}
      />
    </Form.Item>
  );
};
export const InputSelect = ({
  input,
  meta: { touched, error },
  data,
  dafaultItemName,
  idComponent,
  label,
  additionalHandleChangeFunc,
  customKeyValue,
  customKeyLabel,
  showSearch,
  onSearch,
  isLoading,
  filterOption,
  disabled,
  addLabel,
  addAction,
  descriptionValue,
}: IPropsSelect) => {
  const items = data.map((item, key) => (
    <Select.Option
      id={`optionValue${label}-${key}`}
      key={item.id}
      value={customKeyValue ? item[customKeyValue] : item.id}
      name={item.name}
    >
      {descriptionValue ? (
        <React.Fragment>
          <p className="selectDescTitle">
            {customKeyLabel ? item[customKeyLabel] : item.name}
          </p>
          <p className="selectDescDecription">{item[descriptionValue]}</p>
        </React.Fragment>
      ) : customKeyLabel ? (
        item[customKeyLabel]
      ) : (
        item.name
      )}
    </Select.Option>
  ));
  const handleOnChange = e => {
    input.onChange(e);
    if (additionalHandleChangeFunc) {
      additionalHandleChangeFunc();
    }
  };
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
    </span>
  );
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Select
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        style={defaultStyleInput}
        showSearch={showSearch}
        optionFilterProp="name"
        filterOption={filterOption}
        onSearch={onSearch}
        loading={isLoading}
        disabled={disabled}
        dropdownRender={menu => (
          <div>
            {menu}
            {addLabel ? (
              <div
                className="btnAddItemSelect"
                onMouseDown={e => e.preventDefault()}
                onClick={addAction}
              >
                <Icon type="plus" /> {addLabel}
              </div>
            ) : null}
          </div>
        )}
      >
        {dafaultItemName ? (
          <Select.Option value="">{dafaultItemName}</Select.Option>
        ) : null}
        {items}
      </Select>
    </Form.Item>
  );
};

export const InputPassword = ({
  input,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  placeholder,
}: IPropsText) => {
  const handleOnChange = e => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Input.Password
        prefix={icon ? <Icon type={icon} style={defaultStyle} /> : null}
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export const InputTimePicker = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  formatTime,
}) => {
  const handleOnChange = e => input.onChange(e);
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
    </span>
  );
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <TimePicker
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value !== '' ? moment(input.value, 'HH:mm:ss') : undefined}
        defaultOpenValue={moment('12:00:00', 'HH:mm:ss')}
        disabled={disabled}
        format={formatTime ? formatTime : 'HH:mm:ss'}
      />
    </Form.Item>
  );
};
