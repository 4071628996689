import { all, fork } from 'redux-saga/effects';
import {
  changePasswordAction,
  login,
  renewToken,
} from '../../Auth/Saga/AuthSaga';
import {
  deleteCompetitionAction,
  getCompetitionDetailAction,
  getCompetitionListAction,
  setCompetitionStatusAction,
  submitNewCompetitionAction,
  updateCompetitionAction,
} from '../../Competition/Saga/CompetitionSaga';
import {
  deleteParticipantAction,
  generateReportAction,
  getParticipantDetailAction,
  getParticipantListAction,
} from '../../Participant/Saga/ParticipantSaga';
import {
  deleteWinnerAction,
  getWinnerDetailAction,
  getWinnerListAction,
  submitNewWinnerAction,
  updateWinnerAction,
  updateWinnerDescriptionAction,
} from '../../Winner/Saga/WinnerSaga';

import { handleErrorAction } from '../../App/Saga/AppSaga';

export default function* () {
  yield all([
    fork(login),
    fork(renewToken),
    fork(handleErrorAction),
    fork(changePasswordAction),

    fork(getCompetitionListAction),
    fork(submitNewCompetitionAction),
    fork(updateCompetitionAction),
    fork(deleteCompetitionAction),
    fork(getCompetitionDetailAction),
    fork(setCompetitionStatusAction),

    fork(getParticipantListAction),
    fork(deleteParticipantAction),
    fork(getParticipantDetailAction),
    fork(generateReportAction),

    fork(getWinnerDetailAction),
    fork(getWinnerListAction),
    fork(updateWinnerDescriptionAction),
    fork(deleteWinnerAction),
    fork(submitNewWinnerAction),
    fork(updateWinnerAction),
  ]);
}
