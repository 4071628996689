import { createSelector } from 'reselect';
const selectorUppy = state => state.uppy;

export const makeCompetitionLogoSelector = () =>
  createSelector(selectorUppy, state => state.competitionLogo);
export const makeCompetitionBannerSelector = () =>
  createSelector(selectorUppy, state => state.competitionBanner);
export const makeCompetitionBackgroundSelector = () =>
  createSelector(selectorUppy, state => state.competitionBackground);
export const makeCompetitionWinnerSelector = () =>
  createSelector(selectorUppy, state => state.winnerPicture);
