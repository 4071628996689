import { Button, Col, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InputSelect, InputText } from '../../../Assets/Components/CInput';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import CModal from '../../../Assets/Components/CModal';
import { InputFile } from '../../../Assets/Components/CInputFile';
import React from 'react';
import { showImagePreview } from '../../../Assets/Components/CImageLightbox';
import { uppyWiner } from '../../../App/Uppy/UppyStore';
import validate from '../Validation/WinnerValidation';

function FormModalWinnerComponent(props) {
  const {
    winnerModalIsShow,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
    initialValues,
    componentAction,
    competitionList,
    token,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="winnerForm" />
      </h3>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        className="btnSubmitModalRoundedGrey"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        Submit
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <React.Fragment>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="competition"
              component={InputSelect}
              data={competitionList}
              idComponent="inputCompetition"
              label={intl.formatMessage({
                id: 'competition',
              })}
            />
            <Field
              name="winner"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderwinner' })}
              idComponent="inputWinner"
              label={intl.formatMessage({
                id: 'winner',
              })}
            />
            <Field
              name="winnerPicture"
              component={InputFile}
              uppyConfig={uppyWiner}
              idComponent="inputWinnerPicture"
              label="inputWinnerPicture"
              formName="winnerForm"
              fieldName="winnerPicture"
              base64ToMeta={true}
            />
            {initialValues && initialValues.picture
              ? showImagePreview(
                  initialValues.picture,
                  'CompetitionLogo',
                  componentAction,
                  token,
                  'winner'
                )
              : null}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  return (
    <CModal
      modalIsShow={winnerModalIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={loading}
    />
  );
}

const ModalWinnerComponent = reduxForm({
  form: 'winnerForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(injectIntl(FormModalWinnerComponent));
export default ModalWinnerComponent;
