import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionParticipant from '../Store/ParticipantAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsParticipant from '../Selector/ParticipantSelector';

import { bindActionCreators, compose } from 'redux';

import ModalParticipantComponent from '../Component/ModalParticipantComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

function ModalParticipantContainer(props) {
  const { componentAction, participantAction } = props;

  const handleCancel = () => {
    componentAction.openModal('Participant');
    participantAction.resetForm();
  };

  return <ModalParticipantComponent handleCancel={handleCancel} {...props} />;
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.makeIsLoading(),
  participantModalIsShow: SelectorsComponent.participantModalIsShow(),
  initialValues: SelectorsParticipant.makeParticipantDetailSelector(),
  modalParticipantAction: SelectorsParticipant.makeParticipantModalActionSelector(),
});

const mapDispatchToProps = dispatch => ({
  participantAction: bindActionCreators(ActionParticipant, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalParticipantContainer);
