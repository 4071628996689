import {
  IFetchParticipantDetailSuccessAction,
  IFetchParticipantListSuccessAction,
  ISetSelectedParticipantAction,
} from './ParticipantAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedParticipant: '',
  selectedParticipantData: {},
};
export default function ParticipantReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_PARTICIPANT_LIST_FINISHED': {
      const fetchParticipantListSuccessAction = action as IFetchParticipantListSuccessAction;
      newState.list = fetchParticipantListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_PARTICIPANT': {
      const setSelectedParticipantAction = action as ISetSelectedParticipantAction;
      newState.selectedParticipant = setSelectedParticipantAction.id;
      return { ...newState };
    }
    case 'FETCH_PARTICIPANT_DETAIL_FINISHED': {
      const fetchParticipantDetailSuccessAction = action as IFetchParticipantDetailSuccessAction;
      newState.selectedParticipantData =
        fetchParticipantDetailSuccessAction.data;
      return { ...newState };
    }
  }
  return state;
}
