import { createSelector } from 'reselect';

const selectorParticipantList = state => state.ParticipantState;
const selectorFormComponent = state => state.form.filterCompetition.values;

// SELECTOR OTHER
export const makeParticipantListSelector = () =>
  createSelector(selectorParticipantList, state => state.list);
export const makeParticipantSelectedSelector = () =>
  createSelector(selectorParticipantList, state => state.selectedParticipant);
export const makeParticipantDetailSelector = () =>
  createSelector(
    selectorParticipantList,
    state => state.selectedParticipantData
  );
export const makeParticipantModalActionSelector = () =>
  createSelector(
    selectorParticipantList,
    state => state.participantModalAction
  );

// SELECTOR FORM
export const makeFormCompetitionSelector = () =>
  createSelector(selectorFormComponent, state =>
    state ? state.competition : null
  );
